
import { createContext, useContext, useReducer } from "react";
import reducer from '../reducer/filterReducer';
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import { server } from "../util/server";

const FilterContext = createContext();

const initialState = {
    isError: false,
    app_currency: (secureLocalStorage.getItem('POSUserCurrency')) ? secureLocalStorage.getItem('POSUserCurrency') : 'CHF',
    isUserLogin: (secureLocalStorage.getItem('POSlocal_token')) ? secureLocalStorage.getItem('POSlocal_token') : null,
    basicMeta: (secureLocalStorage.getItem('POSmeta')) && (secureLocalStorage.getItem('POSlocal_token')) ? secureLocalStorage.getItem('POSmeta') : null,
    pageNumber: 1,
    hrmSettings: (secureLocalStorage.getItem('POSHRM')) && (secureLocalStorage.getItem('POSHRM')) ? secureLocalStorage.getItem('POSHRM') : 0,
    taxes: (secureLocalStorage.getItem('POStaxes')) && (secureLocalStorage.getItem('POStaxes')) ? secureLocalStorage.getItem('POStaxes') : 0,
    COA: (secureLocalStorage.getItem('POSCOA')) && (secureLocalStorage.getItem('POSCOA')) ? secureLocalStorage.getItem('POSCOA') : 0,

}

export const FilterContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);


    //clear filter
    const clearFilter = (type = null) => {
        dispatch({ type: 'CLEAR_FILTERS', payload: type });
    }

    //currency converter
    const ChangeCurrency = (currency) => {
        dispatch({ type: 'CHANGE_CURRENCY', payload: currency });
    }

    //set customer login
    const setUserLogin = (token) => {
        dispatch({ type: 'USER_LOGIN', payload: token });
    }

    //unset customer login
    const unsetUserLogin = (type = null) => {
        if (type) {

            axios({
                method: "post",
                url:
                    server + "/api/v1/admin/logout",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: state.isUserLogin ? `Bearer ${state.isUserLogin}` : "",
                },
            })
            .then(function (result) {
                if (result.status === 200 && result.data.status === true) {
                    dispatch({ type: 'USER_LOGOUT' });
                }
            })
            .catch(function (error) {
    
            });
        }else{

            dispatch({ type: 'USER_LOGOUT' });
        }
    }

    const updateHRMSettings = (val, type) => {
        dispatch({ type: type, payload: val });
    }



    const setPageNumber = (number) => {
        dispatch({ type: 'SET_PAGE_NUMBER', payload: number });
    }


    return (
        <FilterContext.Provider value={{ ...state, dispatch, clearFilter, ChangeCurrency, setUserLogin, unsetUserLogin, setPageNumber,updateHRMSettings }}>
            {children}
        </FilterContext.Provider>
    )

}

//custom hook
export const useFilterContext = () => {
    return useContext(FilterContext);
}

export const getUniqueBrandData = (array) => {
    let newVel = array.map((item) => { return item['brand']['id'] + ':' + item['brand']['name'] });
    return (newVel = [...new Set(newVel)]);
};
