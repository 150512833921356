
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { Toaster } from 'react-hot-toast';
import RoutesList from "./RoutesList";



function App() {
  
  return (
    <>
    <Toaster
        position="bottom-right"
        reverseOrder={false}
        toastOptions={{
          success: {
            style: {
              background: 'black',
              color: '#fff'
            },
          },
          error: {
            style: {
              background: 'black',
              color: '#fff'
            },
          },
        }}
      />
      <BrowserRouter>
        <>
        <Routes>
				        <Route path="/*" element={<RoutesList />}  />   
			  </Routes> 
        </>
      </BrowserRouter>
    </>
  );
}

export default App;
