import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import icon from "../images/icon.png";
import { useFormik } from "formik";
import { loginSchema } from "../schemas";
import { useFilterContext } from "../context/filterContext";
import axios from "axios";
import { server } from "../util/server";
import { toast } from "react-hot-toast";

const initialValues = {
  email: "",
  password: "",
};
 const Home = () => {
  const { setUserLogin, isUserLogin, basicMeta } = useFilterContext();
  const navigate = useNavigate();

  const [passwordType, setPasswordType] = useState("password");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  useEffect(() => {
    if (isUserLogin && basicMeta) {
      navigate("/admin/dashboard");
    }
  }, [isUserLogin,basicMeta]);

  const submitData = async (query, actions) => {
    try {
      query["session_id"] = sessionStorage.getItem("local_session");
      let res = await axios.post(server + "/api/v1/login", query);
      let data = await res.data;
      if (data.status === false) {
          data.message && toast.error(data.message);

        if (data.errors) {
          Object.keys(data.errors).forEach((key) => {
            data.errors[key].map((item) => {
              toast.error(item);
            });
          });
        }
      } else {
        actions.resetForm();
        setUserLogin(data);
        navigate("/admin/dashboard");
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/')
        }if (error.response && error.response.status === 400) {
        navigate('/')
        } else {
        toast.error("Unexpected error occurred!");
        }
    }
  };

  const { values, handleBlur, handleChange, handleSubmit, errors, touched } =
    useFormik({
      initialValues: initialValues,
      validationSchema: loginSchema,
      onSubmit: async (values, actions) => {
        submitData(values, actions);
      },
    });
  return (
    <div className="align">
      <div className="grid align__item">
        <div className="register">
          <h1
            style={{
              fontSize: "48px",
              marginBottom: "0px",
              marginTop: "0px",
              textAlign: 'center'
            }}
          >
            <img src={icon} style={{ width: "34px" }} alt="icon" /> POS
          </h1>

          <h2 className="fs-6 text-center fw-bold">Sign In to your account</h2>

          <form method="post" onSubmit={handleSubmit} className="form">
            <div className="form__field">
              <label>Email</label>
              <input
                type="email"
                name="email"
                placeholder="info@mailaddress.com"
                value={values.email}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <small className="text-danger fw-bold">
                {errors.email && touched.email && errors.email}
              </small>
            </div>

            <div className="form__field mb-1">
              <label>Password</label>
              <input
                type={passwordType}
                name="password"
                placeholder="••••••••••••"
                value={values.password}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <small className="text-danger fw-bold">
                {errors.password && touched.password && errors.password}
              </small>
            </div>

            <div className="chek-form mb-4 float-end">
              <div className="custome-checkbox">
                <input
                  className="form-check-input password-check"
                  type="checkbox"
                  onClick={togglePassword}
                  id="exampleCheckbox12"
                  value="false"
                />
                <label className="form-check-label" id="register-text" for="exampleCheckbox12">
                  <span>Show Password</span>
                </label>
                <br />
                <small className="text-danger fw-bold"></small>
              </div>
            </div>


            <div className="form__field">
            <button type="submit" className="btn btn-primary login-btn">
              Login
            </button>
              {/* <input type="submit" value="Login" /> */}
            </div>
          </form>

          <p className="text-center">
            <Link to="/forget-password" className="forget-password">
              Forgot Password?
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};
export default Home
    