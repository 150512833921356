import React from 'react'
import Papa  from 'papaparse'

export const PapaParse = () => {
    const handleUploadFile =(event)=>{
        const file = event.target.files[0];
    
    Papa.parse(file, {
        header: true,
        dynamicTyping: true,
        complete: (result) => {
        //   setJsonData(result.data);
        console.log(result)
        },
      });
    }

  return (
    <>
        <h1>CSV to JSON using PapaParse</h1>
    <input type="file" onChange={handleUploadFile} />
    </>
  )
}
