import React from 'react'
import { Navigate } from "react-router-dom";

const AuthorizationCheck = ({isUserLogin,basicMeta,allowed, children, type=null }) => {
    if (!isUserLogin || !basicMeta) {
        return <Navigate to="/" replace />;
    }else if(type && type === 'Employee' && allowed.includes(basicMeta.type) === true){
        return <Navigate to="/403" replace />;
    }
    else if(!type && allowed.includes(basicMeta.type) === false){
        return <Navigate to="/403" replace />;
    }

    return children;
}

export default AuthorizationCheck