import React from 'react'
import { Navigate } from "react-router-dom";

const AuthCheck = ({isUserLogin,basicMeta, children}) => {


    if (!isUserLogin || !basicMeta) {
        return <Navigate to="/" replace />;
    }

    return children;
}

export default AuthCheck