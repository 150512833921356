import * as Yup from 'yup';


export const loginSchema = Yup.object({
    email: Yup.string().email().max(50).required("Email is required"),
    password: Yup.string().min(8, 'Password must contain at least 8 characters').max(20, 'Password must not exceed 20 characters').required("Please enter your password!")
})

export const forgetPasswordSchema = Yup.object({
    email: Yup.string().email().max(50).required("Email is required")
})

export const resetPasswordSchema = Yup.object({
    reset_code: Yup.string().max(50).required("Code is required"),
    password: Yup.string().min(8,'Password must be at least 8 characters!').max(20, 'Password should not exceed 20 characters!').required('Password is required!'),
    password_confirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords does not match!')
})


export const financialPeriodSchema = Yup.object({
    name: Yup.string().max(100).required("Financial Year name is required"),
    start_date: Yup.string().required("Please select start date!"),
    end_date: Yup.string().required("Please select end date!")
})


export const employeePersonalDetailSchema = Yup.object({
    name: Yup.string().max(100).required("Employee name is required!"),
    gender: Yup.string().required("Please select gender!"),
    dob: Yup.string().required("Please select date of birth!"),
    language: Yup.string().required("Please select language!"),
    maritalStatus: Yup.string().required("This field is required!"),
    retirementInsuranceNumber: Yup.string().max(16, 'Number must not exceed than 16 characters!').nullable(),
    phoneNumber: Yup.string().required("Please enter phone number!"),
    email: Yup.string().email().required("Please enter email address!"),
})


export const employeeChildrenDetailSchema = Yup.object({
    name: Yup.string().max(100).required("Name is required!"),
    gender: Yup.string().required("Please select gender!"),
    dob: Yup.string().required("Please select date of birth!"),
    retirementInsuranceNumber: Yup.string().max(16, 'Number must not exceed than 16 characters!').nullable()
})


export const employeeAddressSchema = Yup.object({
    house_number: Yup.string().max(50, 'House Number must not exceed than 50 characters').required('House Number is required!'),
    street: Yup.string().max(60, 'Street must not exceed than 60 characters').required('Street is required!'),
    city: Yup.string().max(10, 'City must not exceed than 10 characters').required('City is required!'),
    zip_code: Yup.string().max(5, 'Zip Code must not exceed than 5 characters').required('Zip Code is required!'),
    country: Yup.string().max(15, 'Country must not exceed than 15 characters').required('Country is required!'),
})


export const employeeBankDetailSchema = Yup.object({
    account_name: Yup.string().max(50, 'Account Name must not exceed than 50 characters').required('Account Name is required!'),
    account_number: Yup.string().max(60, 'Account Number must not exceed than 60 characters').required('Account Number is required!'),
    bank_name: Yup.string().max(40, 'Bank Name must not exceed than 40 characters').required('Bank Name is required!'),
    iban: Yup.string().max(100, 'IBAN must not exceed than 100 characters').required('IBAN is required!'),
    swift_code: Yup.string().max(20, 'Swift Code must not exceed than 20 characters').required('Swift Code is required!'),
})


export const employmentDetailSchema = Yup.object({
    department: Yup.string().required('This field is required!'),
    designation: Yup.string().required('This field is required!'),
    employement_type: Yup.string().required('This field is required!'),
    wages: Yup.string().required('This field is required!'),
    // thirteen_monthly_wage: Yup.string(),
    work_place: Yup.string().required('This field is required!'),
    vacation_days: Yup.string().required('This field is required!'),
    // company_car: Yup.string().required('This field is required!'),
    // free_meals: Yup.string().required('This field is required!'),
    // free_transportation: Yup.string().required('This field is required!'),
})


export const employeeTaskDetailSchema = Yup.object({
    task_date: Yup.string().required('This field is required!'),
    task_name: Yup.string().required('This field is required!'),
    task_description: Yup.string().required('This field is required!'),
    employee_id: Yup.string().required('This field is required!'),
})


export const chartsOfAccountSchema = Yup.object({
    account_subhead: Yup.string().required('This field is required!'),
    account_code: Yup.string().max(4, 'Account Code must be at most 4 characters').required('This field is required!'),
    account_name: Yup.string().max(70, 'Account name must be at most 70 characters').required('This field is required!'),
})

export const productFilterSchema = Yup.object({
    name_de: Yup.string().max(70, 'Name must be up to 70 characters!').required('This field is required!'),
    name_en: Yup.string().max(70, 'Name must be up to 70 characters!').required('This field is required!'),
    name_fr: Yup.string().max(70, 'Name must be up to 70 characters!').required('This field is required!'),
    name_it: Yup.string().max(70, 'Name must be up to 70 characters!').required('This field is required!'),
})

export const wageTypesSchema = Yup.object({
    wage_type: Yup.string().max(6, 'Wage Type must be up to 6 characters!').required('This field is required!'),
    name_de: Yup.string().max(70, 'Name must be up to 70 characters!').required('This field is required!'),
    name_en: Yup.string().max(70, 'Name must be up to 70 characters!').required('This field is required!'),
    name_fr: Yup.string().max(70, 'Name must be up to 70 characters!').required('This field is required!'),
    name_it: Yup.string().max(70, 'Name must be up to 70 characters!').required('This field is required!'),
    desc_de: Yup.string().max(100, 'Description must be up to 100 characters!').nullable(),
    desc_en: Yup.string().max(100, 'Description must be up to 100 characters!').nullable(),
    desc_fr: Yup.string().max(100, 'Description must be up to 100 characters!').nullable(),
    desc_it: Yup.string().max(100, 'Description must be up to 100 characters!').nullable(),
})

export const CustomerSchema = Yup.object({
    first_name: Yup.string().max(30, 'First Name should be upto 30 characters only!').required('This field is required!'),
    last_name: Yup.string().max(30, 'Last Name should be upto 30 characters only!').required('This field is required!'),
    email: Yup.string().email().max(50, 'Email should not exceed 50 characters').required('This field is required!'),
    phone_number: Yup.string().max(20, 'Phone Number should not exceed 20 characters!').nullable(),
    houseNumber: Yup.string().max(20, 'House Number should not exceed 20 characters!').nullable(),
    street: Yup.string().max(20, 'Street should not exceed 20 characters!').nullable(),
    city: Yup.string().max(20, 'City should not exceed 20 characters!').nullable(),
    post_code: Yup.string().max(5, 'Post Code should not exceed 5 characters!').nullable(),
    country: Yup.string().max(50, 'Country Name should not exceed 50 characters!').nullable(),
})


export const SupplierSchema = Yup.object({
    name: Yup.string().max(30, 'First Name should be upto 30 characters only!').required('This field is required!'),
    store_name: Yup.string().max(30, 'Store Name should be upto 30 characters only!').required('This field is required!'),
    store_url: Yup.string().max(50, 'Store URL should be upto 30 characters only!').required('This field is required!'),
    email: Yup.string().email().max(50, 'Email should not exceed 50 characters').required('This field is required!'),
    phone_number: Yup.string().max(20, 'Phone Number should not exceed 20 characters!').required('This field is required!'),
    house_number: Yup.string().max(50, 'House Number/Name should not exceed 50 characters!').nullable(),
    street: Yup.string().max(60, 'Street Number/Name should not exceed 60 characters!').nullable(),
    city: Yup.string().max(50, 'City Name should not exceed 50 characters!').nullable(),
    zip_code: Yup.string().max(5, 'Zip Code should not exceed 5 characters!').nullable(),
    country: Yup.string().max(30, 'Country Name should not exceed 30 characters!').nullable(),
    account_name: Yup.string().max(50, 'Account Name should not exceed 50 characters!').nullable(),
    account_number: Yup.string().max(30, 'Account Number should not exceed 30 characters!').nullable(),
    bank_name: Yup.string().max(40, 'Bank Name should not exceed 40 characters!').nullable(),
    iban: Yup.string().max(30, 'IBAN should not exceed 30 characters!').nullable(),
    swift_code: Yup.string().max(30, 'Swift Code should not exceed 30 characters!').nullable()
})


export const WarehouseSchema = Yup.object({
    outlet_name: Yup.string().max(40, 'Name should not exceed 40 characters').required('This field is required!'),
    phone_number: Yup.string().max(20, 'Phone Number should not exceed 50 characters').nullable(),
    email: Yup.string().max(50, 'Email should not exceed 50 characters').required('This field is required!'),
    fax: Yup.string().max(50, 'FAX should not exceed 50 characters').nullable(),
    website: Yup.string().max(50, 'Website should not exceed 50 characters').nullable(),
    house_number: Yup.string().max(50, 'House Number should not exceed 50 characters').required('This field is required!'),
    street: Yup.string().max(60, 'Street should not exceed 50 characters').required('This field is required!'),
    city: Yup.string().max(50, 'City should not exceed 50 characters').required('This field is required!'),
    zip_code: Yup.string().max(5, 'Zip Code should not exceed 5 characters').required('This field is required!'),
    country: Yup.string().max(50, 'Country name should not exceed 50 characters').required('This field is required!'),
})


export const counterSchema = Yup.object({
    counter_name: Yup.string().max(20, 'Name should not exceed 20 characters').required('Counter Name is required!'),
    counter_number: Yup.string().max(10, 'Counter Number should not exceed 10 characters').required('Counter Number is required!'),
})


export const assignCashierSchema = Yup.object({
    cashier_id: Yup.string().required('This field is required!'),
    cashier_counter_number: Yup.string().required('This field is required!'),
})


export const CourierSchema = Yup.object({
    name: Yup.string().max(50, 'Company Name should be upto 50 characters only!').required('This field is required!'),
    number: Yup.string().max(20, 'Phone number should be upto 20 characters only!').required('This field is required!'),
    email: Yup.string().email().max(50, 'Email should not exceed 50 characters').required('This field is required!'),
    contract_start_date: Yup.string().required('This field is required!'),
    contract_end_date: Yup.string().required('This field is required!'),
    address: Yup.string().max(255, 'Address should not exceed 255 characters!').required('This field is required!'),
})


export const vendorComplaintSchema = Yup.object({
    type: Yup.string().required('This field is required!'),
    subject: Yup.string().max(100, 'Subject should be upto 100 characters only!').required('This field is required!'),
    complaint: Yup.string().max(700, 'Email should not exceed 700 characters').required('This field is required!'),
})


export const VendorPersonalDetailSchema = Yup.object({
    name: Yup.string().max(40, 'Name should be upto 40 characters only!').required('This field is required!'),
    store_name: Yup.string().max(20, 'Store Name should be upto 20 characters only!').required('This field is required!'),
    store_url: Yup.string().max(50, 'Store URL should be upto 50 characters only!').nullable(),
    email: Yup.string().email().max(50, 'Email should not exceed 50 characters').required('This field is required!'),
    phone_number: Yup.string().max(40, 'Phone Number should not exceed 40 characters!').required('This field is required!'),
})


export const VendorPasswordUpdateSchema = Yup.object({
    password: Yup.string().min(8,'Password must be at least 8 characters!').max(20, 'Password should not exceed 20 characters!').required('Password is required!'),
    password_confirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords does not match!')
})


export const categorySchema = Yup.object({
    name_de: Yup.string().max(40, 'Name should not exceed 40 characters!').required('This field is required!'),
    name_en: Yup.string().max(40, 'Name should not exceed 40 characters!').required('This field is required!'),
    name_fr: Yup.string().max(40, 'Name should not exceed 40 characters!').required('This field is required!'),
    name_it: Yup.string().max(40, 'Name should not exceed 40 characters!').required('This field is required!'),
})


export const productTransferSchema = Yup.object({
    transfer_no: Yup.string().max(8, 'Transfer Number should not exceed 8 characters!').required('This field is required!'),
    from_store: Yup.string().required('This field is required!'),
    to_store: Yup.string().required('This field is required!'),
})

export const purchaseOrderReturnSchema = Yup.object({
    return_no: Yup.string().max(10, 'Purchase Return Number should not exceed 10 characters!').required('This field is required!'),
    supplier: Yup.string().required('This field is required!'),
    shipping_company: Yup.string().nullable(),
    shipping_cost: Yup.string().nullable(),
})

export const purchaseOrderSchema = Yup.object({
    purchase_no: Yup.string().max(10, 'Purchase Order Number should not exceed 10 characters!').required('This field is required!'),
    supplier: Yup.string().required('This field is required!'),
    deliver_to: Yup.string().required('This field is required!'),
    order_note: Yup.string().max(150, 'Order note must be up to 150 characters!').nullable(),
})


export const productsSchema = Yup.object({
    name_de: Yup.string().required('This field is required!'),
    name_en: Yup.string().required('This field is required!'),
    name_fr: Yup.string().required('This field is required!'),
    name_it: Yup.string().required('This field is required!'),
    default_category: Yup.string().required('This field is required!'),
    brand_id: Yup.string().required('This field is required!'),
    supplier_id: Yup.string().required('This field is required!'),
    stock: Yup.string().required('This field is required!')
})


export const taxRateSchema = Yup.object({
    valid_from: Yup.string().required('This field is required!'),
    valid_till: Yup.string().required('This field is required!'),
    tax_code: Yup.string().max(70, 'Tax Code must be at most 70 characters').required('This field is required!'),
    tax_rate: Yup.string().max(6, 'Tax Rate must be at most 6 characters!').required('This field is required!'),
    vat_form_digit: Yup.number().required('This field is required!'),
    tax_desc: Yup.string().max(100, 'Tax Description must be at most 100 characters').required('This field is required!'),
})


export const invoiceSchema = Yup.object({
    category: Yup.string().required('This field is required!').max(25, 'Category must be at most 3 characters!'),
    reference_num: Yup.string().max(25, 'Reference number must be at most 25 characters!').nullable(),
    voucher_date: Yup.string().required('This field is required!'),
    voucher_source: Yup.string().required('This field is required!'),
    voucher_description: Yup.string().max(150, 'Description must be at most 150 characters!').nullable(),
})


export const productTransferEmailSchema = Yup.object({
    recepient_email: Yup.string().required('This field is required!').max(100, 'Recepient Email must be at most 100 characters!'),
    subject: Yup.string().max(50, 'Subject must be at most 50 characters!').nullable(),
})


export const insurance_pension_settingSchema = Yup.object({
    setting_type: Yup.string().required('This field is required!').max(10, 'Type must be at most 10 characters!'),
    deduct_amount: Yup.string().required('This field is required!').max(7, 'Amount must be at most 7 characters!'),
    minimum_salary: Yup.string().required('Min. Salary is required!').max(7, 'Min. Salary must be at most 7 characters!'),
    maximum_salary: Yup.string().max(7, 'Max. Salary must be at most 7 characters!').nullable(),
    minimum_age: Yup.string().required('Min. Age is required!').max(2, 'Min. Age must be at most 2 characters!'),
    maximum_age: Yup.string().required('Max. Age is required!').max(2, 'Max Age must be at most 2 characters!'),
})

export const salesPymentUpdateStatusSchema = Yup.object({
    cash_given: Yup.string(),
    card_digit: Yup.string().required('Card digit is required!').max(4, 'Card digit must be at most 4 characters!'),
    card_type: Yup.string().required('Card type is required!').max(25, 'Card type be at most 25 characters!'),
    invoice_number: Yup.string().required('Invoice number is required!').max(4, 'Invoice number must be at most 4 characters!')
})