import  secureLocalStorage  from  "react-secure-storage";

const filterReducer = (state, action) => {
    switch (action.type) {
        case "API_ERROR":
            return {
                ...state,
                isError:true
            };

      

        case "SET_PAGE_NUMBER":
            return{
                ...state,
                pageNumber:action.payload
            }

        case "CHANGE_CURRENCY":
            secureLocalStorage.setItem('POSUserCurrency', action.payload)
            return{
                ...state,
                app_currency:action.payload
            }

        case "HRM_SETTINGS":
            secureLocalStorage.removeItem('POSHRM')
            secureLocalStorage.setItem('POSHRM', action.payload)
            return{
                ...state,
                hrmSettings:action.payload
            }

        case "ACCOUNTING_TAXES":
                secureLocalStorage.removeItem('POStaxes')
                secureLocalStorage.setItem('POStaxes', action.payload)
                return{
                    ...state,
                    taxes:action.payload
                }

        case "ACCOUNTING_COA":
            secureLocalStorage.removeItem('POSCOA')
            secureLocalStorage.setItem('POSCOA', action.payload)
            return{
                ...state,
                COA:action.payload
            }

        case "USER_LOGIN":
            secureLocalStorage.removeItem('status')
            secureLocalStorage.removeItem('POSlocal_token');
            secureLocalStorage.removeItem('POSmeta')
            secureLocalStorage.removeItem('POSUserCurrency')
            secureLocalStorage.removeItem('POSHRM')
            secureLocalStorage.removeItem('POStaxes')
            secureLocalStorage.removeItem('POSCOA')
            secureLocalStorage.setItem('POSlocal_token', action.payload.token)
            secureLocalStorage.setItem('POSmeta', action.payload.meta)
            secureLocalStorage.setItem('POSUserCurrency', action.payload.meta.currency)
            secureLocalStorage.setItem('POSHRM', action.payload.meta.hrm_settings)
            secureLocalStorage.setItem('POSCOA', action.payload.meta.cha_of_acc)
            secureLocalStorage.setItem('POStaxes', action.payload.meta.taxes)
            return{
                ...state,
                isUserLogin:action.payload.token,
                basicMeta:action.payload.meta,
                app_currency:action.payload.meta.currency,
                hrmSettings:action.payload.meta.hrm_settings,
            }

        case "USER_LOGOUT":
            secureLocalStorage.removeItem('POSlocal_token');
            secureLocalStorage.removeItem('POSmeta')
            secureLocalStorage.removeItem('status')
            secureLocalStorage.removeItem('POSUserCurrency')
            secureLocalStorage.removeItem('POSHRM')
            secureLocalStorage.removeItem('POSCOA')
            secureLocalStorage.removeItem('POStaxes')
            return{
                ...state,
                isUserLogin:null,
                basicMeta:null
            }
    
        default:
            return state;
    }
}

export default filterReducer;