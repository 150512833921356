import React ,{lazy,Suspense} from 'react'
import { Routes, Route } from "react-router-dom";
import { useFilterContext } from './context/filterContext';
import AuthorizationCheck from "./pages/AuthorizationCheck";
import AuthCheck from "./pages/AuthCheck";
import Home from './pages/Home';
import {PapaParse} from "./pages/PapaParse";
const Dashboard = lazy(() => import ("./pages/dashboard/Dashboard"));
const ChartsOfAccount = lazy(() => import ("./pages/accounting/ChartsOfAccount"));
const TaxRates = lazy(() => import ("./pages/accounting/TaxRates"));
const Settings = lazy(() => import ("./pages/accounting/Settings"));
const Invoices = lazy(() => import ("./pages/accounting/Invoices"));
const Budgets = lazy(() => import ("./pages/accounting/Budgets"));
const Employees = lazy(() => import ("./pages/HRM/Employees"));
const EmployeeTask = lazy(() => import ("./pages/HRM/EmployeeTask"));
const EmployeeAttendance = lazy(() => import ("./pages/HRM/EmployeeAttendance"));
const ProductFilter = lazy(() => import ("./pages/Products/ProductFilter"));
const Categories = lazy(() => import ("./pages/Categories/Categories"));
const Departments = lazy(() => import ("./pages/HRM/Departments"));
const Supplier = lazy(() => import ("./pages/Purchases/Supplier"));
const ProductTransfer = lazy(() => import ("./pages/inventory/ProductTransfer"));
const PurchaseOrders =lazy(() => import ( "./pages/Purchases/PurchaseOrders"));
const PurchaseOrderReturn = lazy(() => import ("./pages/Purchases/PurchaseOrderReturn"))
const Customers = lazy(() => import ("./pages/Sales/Customers"));
const Warehouse = lazy(() => import ("./pages/Warehouse/Warehouse"));
const Outlet = lazy(() => import ("./pages/Outlet/Outlet"));
const Products = lazy(() => import ("./pages/Products/Products"));
const Currencies = lazy(() => import ("./pages/Products/Currencies"));
const HRMSettings = lazy(() => import ("./pages/HRM/HRMSettings"));
const WageTypes = lazy(() => import ("./pages/HRM/WageTypes"));
const EmployeeDesignations = lazy(() => import ('./pages/HRM/EmployeeDesignations'))
const BasicSettings = lazy(() => import ("./pages/BasicSettings"));
const ShippingCompanies = lazy(() => import ("./pages/BasicSettings/ShippingCompanies"));
const Test = lazy(() => import ("./pages/Test"));
const Complaints = lazy(() => import ("./pages/Complaints/Complaints"));
const UpdateProfile = lazy(() => import ("./pages/UpdateProfile"));
const UpdatePassword = lazy(() => import ("./pages/UpdatePassword"));
const Sales = lazy(() => import ("./pages/Sales/Sales"));
const DailyTasks = lazy(() => import ("./pages/employees/DailyTasks"));
const Attendance = lazy(() => import ("./pages/employees/Attendance"));
const Imports = lazy(() => import ("./pages/Imports"));
const Stats = lazy(() => import ("./pages/stats/Stats"));
const Reports = lazy(() => import ("./pages/Reports"));
const DailyReports = lazy(() => import ("./pages/daily_reports/DailyReports"));
const Payroll = lazy(() => import ("./pages/HRM/Payroll"));
const PensionInsuranceSettings = lazy(() => import ("./pages/HRM/PensionInsuranceSettings"));
const ForgetPassword = lazy(() => import ("./pages/ForgetPassword"));
const ResetPassword = lazy(() => import ("./pages/ResetPassword"));

const EmailTemplates = lazy(() => import ("./pages/BasicSettings/EmailTemplates"));
const Error403 = lazy(() => import ("./pages/error/Error403"));

const Header = lazy(() => import ('./components/layout/Header'));
const Footer = lazy(() => import ('./components/layout/Footer'));

const FinancialPeriod = lazy(() => import ("./pages/accounting/FinancialPeriod"));
const Brands = lazy(() => import ("./pages/Brands/Brands"));
const ImportProgress = lazy(() => import ("./components/ImportProgress"));
const RoutesList = () => {
    const { isUserLogin,basicMeta } = useFilterContext();
  return (
          <div className="container">
            {isUserLogin && <Header />}
            <div className="container-body">
            <Suspense fallback="Loading....">
            <Routes>
                <Route path="/chunks" element={<PapaParse />} />
                <Route path="/" element={<Home />}></Route>
                <Route path="/403" element={<Error403 />}></Route>
                <Route path="/forget-password" element={<ForgetPassword />}></Route>
                <Route path="/reset-password" element={<ResetPassword />}></Route>

                <Route path="/admin/dashboard" element={
                  <AuthCheck isUserLogin={isUserLogin} basicMeta={basicMeta}>
                    <Dashboard />
                  </AuthCheck>
                } />

                <Route path="/admin/outlet" element={
                  <AuthorizationCheck isUserLogin={isUserLogin} basicMeta={basicMeta} allowed={['Vendor']}>
                    <Outlet />
                  </AuthorizationCheck>
                } />
                <Route path="/admin/warehouse" element={
                  <AuthorizationCheck isUserLogin={isUserLogin} basicMeta={basicMeta} allowed={['Vendor']}>
                    <Warehouse />
                  </AuthorizationCheck>
                } />

                <Route path="/admin/brands/:slug" element={
                  <AuthorizationCheck isUserLogin={isUserLogin} basicMeta={basicMeta} allowed={['Vendor']}>
                    <Brands />
                  </AuthorizationCheck>
                } />
                <Route path="/admin/product-categories/:slug" element={
                  <AuthorizationCheck isUserLogin={isUserLogin} basicMeta={basicMeta} allowed={['Vendor']}>
                    <Categories />
                  </AuthorizationCheck>
                } />
                <Route path="/admin/product-filters/:slug" element={
                  <AuthorizationCheck isUserLogin={isUserLogin} basicMeta={basicMeta} allowed={['Vendor']}>
                    <ProductFilter />
                  </AuthorizationCheck>
                } />
                <Route path="/admin/currencies/:slug" element={
                  <AuthorizationCheck isUserLogin={isUserLogin} basicMeta={basicMeta} allowed={['Vendor']}>
                    <Currencies />
                  </AuthorizationCheck>
                } />
                <Route path="/admin/products/:slug/:id?" element={
                  <AuthorizationCheck isUserLogin={isUserLogin} basicMeta={basicMeta} allowed={['Vendor','Cashier']}>
                    <Products />
                  </AuthorizationCheck>
                }  />

                <Route path="/admin/import-progress/:slug" element={
                  <AuthorizationCheck isUserLogin={isUserLogin} basicMeta={basicMeta} allowed={['Vendor','Cashier']}>
                    <ImportProgress />
                  </AuthorizationCheck>
                }  />

                <Route path="/admin/accounting/financial-period/:slug/:id?" element={
                  <AuthorizationCheck isUserLogin={isUserLogin} basicMeta={basicMeta} allowed={['Vendor']}>
                    <FinancialPeriod />
                  </AuthorizationCheck>
                } />
                <Route path="/admin/accounting/chart-of-accounts/:slug/:id?" element={
                  <AuthorizationCheck isUserLogin={isUserLogin} basicMeta={basicMeta} allowed={['Vendor']}>
                    <ChartsOfAccount />
                  </AuthorizationCheck>
                } />
                <Route path="/admin/accounting/invoices/:slug/:id?" element={
                  <AuthorizationCheck isUserLogin={isUserLogin} basicMeta={basicMeta} allowed={['Vendor']}>
                    <Invoices />
                  </AuthorizationCheck>
                } />
                <Route path="/admin/accounting/tax-rate/:slug/:id?" element={
                  <AuthorizationCheck isUserLogin={isUserLogin} basicMeta={basicMeta} allowed={['Vendor']}>
                    <TaxRates />
                  </AuthorizationCheck>
                } />
                <Route path="/admin/accounting/budgets/:slug/:id?" element={
                  <AuthorizationCheck isUserLogin={isUserLogin} basicMeta={basicMeta} allowed={['Vendor']}>
                    <Budgets />
                  </AuthorizationCheck>
                } />

              
                <Route path="/admin/inventory/product/transfer/:slug/:id?" element={
                  <AuthorizationCheck isUserLogin={isUserLogin} basicMeta={basicMeta} allowed={['Vendor']}>
                    <ProductTransfer />
                  </AuthorizationCheck>
                } />
                

                <Route path="/admin/hrm/employees/:slug/:id?" element={
                  <AuthorizationCheck isUserLogin={isUserLogin} basicMeta={basicMeta} allowed={['Vendor']}>
                    <Employees />
                  </AuthorizationCheck>
                } />
                <Route path="/admin/hrm/employee-tasks/:slug/:id?" element={
                  <AuthorizationCheck isUserLogin={isUserLogin} basicMeta={basicMeta} allowed={['Vendor']}>
                    <EmployeeTask />
                  </AuthorizationCheck>
                } />
                <Route path="/admin/hrm/employee-attendance/:slug/:id?" element={
                  <AuthorizationCheck isUserLogin={isUserLogin} basicMeta={basicMeta} allowed={['Vendor']}>
                    <EmployeeAttendance />
                  </AuthorizationCheck>
                } />
                <Route path="/admin/hrm/departments/:slug" element={
                  <AuthorizationCheck isUserLogin={isUserLogin} basicMeta={basicMeta} allowed={['Vendor']}>
                    <Departments />
                  </AuthorizationCheck>
                } />
                <Route path="/admin/hrm/employee-designations/:slug"  element={
                  <AuthorizationCheck isUserLogin={isUserLogin} basicMeta={basicMeta} allowed={['Vendor']}>
                    <EmployeeDesignations />
                  </AuthorizationCheck>
                } />
                <Route path="/admin/hrm/wage-types/:slug" element={
                  <AuthorizationCheck isUserLogin={isUserLogin} basicMeta={basicMeta} allowed={['Vendor']}>
                    <WageTypes />
                  </AuthorizationCheck>
                } />
                <Route path="/admin/hrm/insurance-&-pension/:slug/:id?" element={
                  <AuthorizationCheck isUserLogin={isUserLogin} basicMeta={basicMeta} allowed={['Vendor']}>
                    <PensionInsuranceSettings />
                  </AuthorizationCheck>
                } />

                <Route path="/admin/hrm/payroll/:slug/:id?" element={
                  <AuthCheck isUserLogin={isUserLogin} basicMeta={basicMeta} allowed={['Vendor']}>
                    <Payroll />
                  </AuthCheck>
                } />

                <Route path="/admin/hrm/settings" element={
                  <AuthorizationCheck isUserLogin={isUserLogin} basicMeta={basicMeta} allowed={['Vendor']}>
                    <HRMSettings />
                  </AuthorizationCheck>
                } />


                <Route path="/admin/supplier/:slug/:id?" element={
                  <AuthorizationCheck isUserLogin={isUserLogin} basicMeta={basicMeta} allowed={['Vendor']}>
                    <Supplier />
                  </AuthorizationCheck>
                } />
                <Route path="/admin/purchase-orders/:slug/:id?" element={
                  <AuthorizationCheck isUserLogin={isUserLogin} basicMeta={basicMeta} allowed={['Vendor']}>
                    <PurchaseOrders />
                  </AuthorizationCheck>
                } />
                <Route path="/admin/purchase-order-return/:slug/:id?" element={
                  <AuthorizationCheck isUserLogin={isUserLogin} basicMeta={basicMeta} allowed={['Vendor']}>
                    <PurchaseOrderReturn />
                  </AuthorizationCheck>
                } />

                <Route path="/admin/sales/customers/:slug/:id?" element={
                  <AuthorizationCheck isUserLogin={isUserLogin} basicMeta={basicMeta} allowed={['Vendor','Cashier']}>
                    <Customers />
                  </AuthorizationCheck>
                } />
                <Route path="/admin/sales/orders/:slug/:id?" element={
                  <AuthorizationCheck isUserLogin={isUserLogin} basicMeta={basicMeta} allowed={['Vendor','Cashier']}>
                    <Sales />
                  </AuthorizationCheck>
                } />
                
                <Route path="/admin/accounting/settings" element={
                  <AuthorizationCheck isUserLogin={isUserLogin} basicMeta={basicMeta} allowed={['Vendor']}>
                    <Settings />
                  </AuthorizationCheck>
                } />

                <Route path="/admin/settings" element={
                  <AuthorizationCheck isUserLogin={isUserLogin} basicMeta={basicMeta} allowed={['Vendor']}>
                    <BasicSettings />
                  </AuthorizationCheck>
                } />
                <Route path="/admin/shipping-companies/:slug/:id?" element={
                  <AuthorizationCheck isUserLogin={isUserLogin} basicMeta={basicMeta} allowed={['Vendor']}>
                    <ShippingCompanies />
                  </AuthorizationCheck>
                } />
                <Route path="/admin/complaints/:slug/:id?" element={
                  <AuthorizationCheck isUserLogin={isUserLogin} basicMeta={basicMeta} allowed={['Vendor']}>
                    <Complaints />
                  </AuthorizationCheck>
                } />
                <Route path="/admin/email-template" element={
                  <AuthorizationCheck isUserLogin={isUserLogin} basicMeta={basicMeta} allowed={['Vendor']}>
                    <EmailTemplates />
                  </AuthorizationCheck>
                } />


                <Route path="/admin/update-profile" element={
                  <AuthorizationCheck isUserLogin={isUserLogin} basicMeta={basicMeta} allowed={['Vendor']}>
                    <UpdateProfile />
                  </AuthorizationCheck>
                } />

                <Route path="/admin/update-password" element={
                  <AuthCheck isUserLogin={isUserLogin} basicMeta={basicMeta}>
                    <UpdatePassword />
                  </AuthCheck>
                } />


                <Route path="/admin/employee-daily-tasks" element={
                  <AuthorizationCheck isUserLogin={isUserLogin} basicMeta={basicMeta} allowed={['Vendor']} type="Employee">
                    <DailyTasks />
                  </AuthorizationCheck>
                } />
                <Route path="/admin/employee-attendance" element={
                  <AuthorizationCheck isUserLogin={isUserLogin} basicMeta={basicMeta} allowed={['Vendor']} type="Employee">
                    <Attendance />
                  </AuthorizationCheck>
                } />

                
                <Route path="/admin/import/:slug" element={
                  <AuthorizationCheck isUserLogin={isUserLogin} basicMeta={basicMeta} allowed={['Vendor']}>
                    <Imports />
                  </AuthorizationCheck>
                } />

          
                <Route path="/admin/reports" element={
                  <AuthorizationCheck isUserLogin={isUserLogin} basicMeta={basicMeta} allowed={['Vendor']}>
                    <Reports />
                  </AuthorizationCheck>
                } />
                <Route path="/admin/stats/:slug/:id?" element={
                  <AuthorizationCheck isUserLogin={isUserLogin} basicMeta={basicMeta} allowed={['Vendor']}>
                    <Stats />
                  </AuthorizationCheck>
                } />
                <Route path="/admin/daily-report/:slug" element={
                  <AuthorizationCheck isUserLogin={isUserLogin} basicMeta={basicMeta} allowed={['Vendor']}>
                    <DailyReports />
                  </AuthorizationCheck>
                } />


                <Route path="/admin/test" element={<Test />}></Route>

              </Routes>
              </Suspense>
            </div>
            {isUserLogin && <Footer />}
          </div>
  )
}

export default RoutesList